<template>
	<div id="app">
		<component :is="layout" class="site-layout">
			<router-view/>
		</component>
	</div>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || 'purchase-layout'
    }
  }
}
</script>

<style>
#app {
	width: 100%;
}

.site-layout {
	max-width: 100vw;
}
</style>
