import './assets/scss/main.scss';

import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import VueMeta from 'vue-meta';

Vue.component('purchase-layout', () => import('./layouts/purchase.vue'));
Vue.component('result-layout', () => import('./layouts/result.vue'));

Vue.use(VueMeta);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
