import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'premium',
        component: () => import('../pages/payment/membership.vue'),
        meta: {
            layout: 'purchase-layout'
        }
    },
    {
        path: '/result',
        name: 'payment_result',
        component: () => import('../pages/payment/result.vue'),
        meta: {
            layout: 'result-layout'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const multiSlashRegex = /\/\/+/g
    if (to && to.fullPath && multiSlashRegex.test(to.fullPath)) {
        let toPath = to.fullPath.replace(multiSlashRegex, '/')
        return next(toPath)
    }
    return next()
})

export default router
